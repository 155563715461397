import React from 'react';
import {
  useRecordContext,
  List as ComponentList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  Edit as ComponentEdit,
  SimpleForm,
  TextInput,
  TopToolbar,
  CreateButton,
  Create as ComponentCreate
} from 'react-admin';

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const PostPanel = () => {
  const record = useRecordContext();
  return (
    <div dangerouslySetInnerHTML={{ __html: record.content }} />
  );
};

export const List = () => {
  return (
    <ComponentList actions={<ListActions/>}>
      <Datagrid expand={<PostPanel />}>
        <TextField source='id' sortable={false} />
        <TextField source='alias' sortable={false} />
        <TextField source='name' sortable={false} />
        <DateField showTime={true} source='createdAt' />
        <DateField showTime={true} source='updatedAt' />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </ComponentList>
  );
};

export const Edit = (props) => {
  return (
    <ComponentEdit title={'Edit notification type'} {...props}>
      <SimpleForm>
        <TextInput disabled source='id' />
        <TextInput source='alias' />
        <TextInput source='name' />
        <TextInput source='icon' />
        <TextInput source='content' multiline fullWidth />
      </SimpleForm>
    </ComponentEdit>
  );
};

export const Create = () => {
  return (
    <ComponentCreate>
      <SimpleForm>
        <TextInput source='alias' />
        <TextInput source='name' />
        <TextInput source='icon' />
        <TextInput source='content' multiline fullWidth />
      </SimpleForm>
    </ComponentCreate>
  )
};

