import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { Cookie } from '../libs/cookie';
import { ReCaptcha } from '../libs/reCaptcha';
import { API_URL, ACCESS_TOKEN, REFRESH_TOKEN, FINGER_PRINT, PROFILE, ROLES, RECAPTCHA_ACTIONS } from '../constants';

let instance = null;

class AuthInstance {
  constructor() {
    if (!instance) {
      this.profile = null;
      this.isAlreadyFetchingAccessToken = false;

      instance = this;
    }

    return instance;
  }

  async _getToken(phone, password) {
    if (!phone || !password) {
      throw new Error('Error login data!');
    }

    const functionBody = async (captchaToken) => {
      const fingerPrint = this.getFingerPrint();

      if (!fingerPrint) {
        throw new Error('Fingerprint not found!');
      }

      const headers = { 'Fingerprint': fingerPrint };
      const data = { phone, password, captchaToken };
      const response = await axios({
        method: 'post',
        data,
        url: `${API_URL}/auth/login`,
        headers
      });

      if (!response.data.success) {
        throw new Error(response.data.error);
      }

      return response.data.data;
    };

    return ReCaptcha.execute(RECAPTCHA_ACTIONS.login, functionBody);
  };

  async _getProfile(accessToken) {
    if (!accessToken) {
      throw new Error('AccessToken not found!');
    }

    const headers = { 'Authorization': `Bearer ${accessToken}` };
    const response = await axios({
      method: 'get',
      url: `${API_URL}/users/profile`,
      headers
    });

    if (!response.data.success) {
      throw new Error(response.data.error);
    }

    return response.data.data;
  }

  async _refreshTokens(currentRefreshToken, fingerPrint) {
    if (!currentRefreshToken || !fingerPrint) {
      return false;
    }

    if (this.isAlreadyFetchingAccessToken) {
      return false;
    }

    this.isAlreadyFetchingAccessToken = true;

    try {
      const headers = { 'Fingerprint': fingerPrint };

      const response = await axios({
        url: `${API_URL}/auth/refreshtoken`,
        method: 'post',
        data: {
          refreshToken: currentRefreshToken,
        },
        headers,
      });

      const { accessToken, refreshToken, id } = response.data.data;

      this._setAuthData({ accessToken, refreshToken, id });

      return true;
    } catch (error) {
      this.logout();

      return false;
    } finally {
      this.isAlreadyFetchingAccessToken = false;
    }
  }

  _checkValidToken(expire) {
    return Number(expire) > Date.now();
  }

  _removeUserData() {
    Cookie.remove(ACCESS_TOKEN);
    Cookie.remove(REFRESH_TOKEN);
    Cookie.remove(PROFILE);

    this.profile = null;
  }

  login({ username, password }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { accessToken, refreshToken, id } = await this._getToken(username, password);

        this.profile = await this._getProfile(accessToken.code);

        if (![ROLES.admin, ROLES.moderator].includes(this.profile.role)) {
          return reject('Permissions error');
        }

        this._setAuthData({ accessToken, refreshToken, id, profile: this.profile });

        return resolve();
      } catch (error) {
        reject(error.response ? error.response.data : error);
      }
    });
  }

  logout() {
    return new Promise((resolve) => {
      this._removeUserData();

      resolve();
    });
  }

  checkError(error) {
    /* todo Доделать*/
    console.log(error); // eslint-disable-line

    const { status } = error;
    if (status === 401) {
      this._removeUserData();

      return Promise.reject();
    }

    return Promise.resolve();
  }

  checkAuth() {
    return new Promise( async (resolve, reject) => {
      const { expire } = this.getAccessToken();
      const refreshToken = this.getRefreshToken();
      const fingerPrint = this.getFingerPrint();

      try {
        if (!expire) {
          return reject('error expire');
        }

        if (!this._checkValidToken(expire)) {
          const result = await this._refreshTokens(refreshToken, fingerPrint);

          if (result) {
            resolve();
          } else {
            reject();
          }
        }

        resolve();
      } catch ({ response: { data }}) {
        reject(data)
      }
    });
  }

  getPermissions() {
    return new Promise(async (resolve, reject) => {
      const profile = this.profile || this._getStoreProfile();

      if (!profile) {
        this.logout();

        return reject('Profile not found!');
      }

      if ([ROLES.admin, ROLES.moderator].includes(profile.role)) {
        resolve(profile.role);
      } else {
        this.logout();

        return reject('Permissions error');
      }
    });
  }

  _setAuthData({ accessToken, refreshToken, id, profile = null }) {
    if (!accessToken || !refreshToken) {
      throw new Error('Invalid token data');
    }

    const secure = false;
    const value = {
      ...accessToken,
      id
    };
    const expires = 3 * 86400; // 86400 сек 1 день
    const path = '/';

    Cookie.set(ACCESS_TOKEN, JSON.stringify(value), { expires, secure, path });
    Cookie.set(REFRESH_TOKEN, refreshToken, { expires, secure, path });

    if (profile) {
      Cookie.set(PROFILE, JSON.stringify(profile), { expires, secure, path });
    }
  }

  getAccessToken() {
    return Cookie.getJSON(ACCESS_TOKEN) || {};
  }

  getRefreshToken() {
    return Cookie.get(REFRESH_TOKEN) || null;
  }

  _getStoreProfile() {
    return Cookie.getJSON(PROFILE) || null;
  }

  setFingerPrint() {
    const fingerPrint = uuidv4();
    const secure = false;
    const expires = 14 * 365 * 86400; // 86400 сек 1 день
    const path = '/';

    Cookie.set(FINGER_PRINT, fingerPrint, { expires, secure, path });
  }

  getFingerPrint() {
    return Cookie.get(FINGER_PRINT);
  }

  getHeaders() {
    const { code } = this.getAccessToken();
    const fp = this.getFingerPrint();

    return {
      'Authorization': code,
      'Fingerprint': fp,
    }
  }
}

export const Auth = new AuthInstance();
