import Cookies from 'js-cookie'

export class Cookie {
  static get(key) {
    return Cookies.get(key);
  }

  static getJSON(key) {
    try {
      return JSON.parse(Cookies.get(key));
    } catch (error) {
      return null;
    }
  }

  static set(key, value, params) {
    Cookies.set(key, value, params);
  }

  static remove(key) {
    Cookies.remove(key, { path: '/' });
  }

  static has(key) {
    return !!Cookies.get(key);
  }
}
