import React from 'react';
import {
  useRecordContext,
  List as ComponentList,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin';

const PostPanel = () => {
  const record = useRecordContext();
  return (
    <div dangerouslySetInnerHTML={{ __html: record.text }} />
  );
};

export const List = () => {
  return (
    <ComponentList>
      <Datagrid expand={<PostPanel />}>
        <TextField source='id' sortable={false} />
        <TextField label={'To whom'} source='UserId' sortable={false} />
        <TextField source='link' sortable={false} />
        <TextField source='params' sortable={false} />
        <TextField source='NotificationTypeId' sortable={false} />
        <DateField showTime={true} source='createdAt' />
        <DateField showTime={true} source='updatedAt' />
      </Datagrid>
    </ComponentList>
  );
};
