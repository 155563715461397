import React from 'react';
import {
  useRecordContext,
  List as ComponentList,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin';

const PostPanel = () => {
  const record = useRecordContext();
  return (
    <div dangerouslySetInnerHTML={{ __html: record.text }} />
  );
};

export const List = () => {
  return (
      <ComponentList>
        <Datagrid expand={<PostPanel />} expandSingle>
          <TextField source='id' />
          <TextField source='name' sortable={false} />
          <TextField source='status' sortable={false} />
          <TextField label={'User ID'} source='User.id' />
          <DateField showTime={true} source='createdAt' />
          <DateField showTime={true} source='updatedAt' />
        </Datagrid>
      </ComponentList>
  );
};
