import React from 'react';
import {
  useRecordContext,
  List as ComponentList,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin';

const PostPanel = () => {
  const record = useRecordContext();
  return (
    <div dangerouslySetInnerHTML={{ __html: record.text }} />
  );
};

export const List = (props) => {
  return (
    <ComponentList {...props}>
      <Datagrid expand={<PostPanel />}>
        <TextField source='id' sortable={false} />
        <TextField source='status' sortable={false} />
        <TextField label={'Publication ID'} source='Publication.id' sortable={false} />
        <TextField label={'User ID'} source='User.id' sortable={false} />
        <DateField showTime={true} source='createdAt' sortable={false} />
        <DateField showTime={true} source='updatedAt' sortable={false} />
      </Datagrid>
    </ComponentList>
  );
};
