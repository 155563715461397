import React from 'react';
import {
  List as ComponentList,
  Edit as ComponentEdit,
  EditButton,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  DateField,
  DateInput,
  RadioButtonGroupInput,
  Toolbar,
  SaveButton
} from 'react-admin';

export const List = (props) => {
  return (
    <ComponentList {...props}>
      <Datagrid>
        <TextField source='User.id' sortable={false} />
        <TextField label={'Name'} source='name' sortable={false} />
        <TextField label={'Phone'} source='User.phone' sortable={false} />
        <TextField label={'Role'} source='User.Role.name' sortable={false} />
        <TextField label={'Status'} source='User.status' sortable={false} />
        <DateField label={'Created At'} showTime={true} source='User.createdAt' sortable={false} />
        <EditButton />
      </Datagrid>
    </ComponentList>
  );
};

const CustomToolbar = () => <Toolbar><SaveButton /></Toolbar>;

export const Edit = () => {
  return (
    <ComponentEdit title={'Edit user'}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source='name' />
        <DateInput source='birth' />
        <TextInput multiline fullWidth source='about' />
        <RadioButtonGroupInput source="sex" choices={[
          { id: 'none', name: 'None' },
          { id: 'man', name: 'Man' },
          { id: 'woman', name: 'Woman' },
        ]} />
      </SimpleForm>
    </ComponentEdit>
  );
};

