export const ENVIRONMENT = process.env.REACT_APP_NODE_ENV;

export const PROD_ORIGIN = 'https://rabotkartonka.ru';
export const DEV_ORIGIN = 'https://rabotkartonka-dev.ru';
export const API_PROD_DOMAIN = 'https://api.rabotkartonka.ru';
export const API_DEV_DOMAIN = 'https://api.rabotkartonka-dev.ru';
export const LOCAL_ORIGIN = 'http://localhost';
export const API_PORT = 3100;

// Mode
export const IS_LOCAL = ENVIRONMENT === 'local'; // локальный запуск
export const IS_DEVELOPMENT = ENVIRONMENT === 'development'; // на тестовом сервере
export const IS_PRODUCTION = ENVIRONMENT === 'production'; // Продакшн

export const API_HOSTNAME = IS_PRODUCTION ? API_PROD_DOMAIN : IS_DEVELOPMENT ? API_DEV_DOMAIN : `${LOCAL_ORIGIN}:${API_PORT}`;
export const API_URL = `${API_HOSTNAME}/api`;

// Storage
export const ACCESS_TOKEN = 'at';
export const REFRESH_TOKEN = 'rt';
export const FINGER_PRINT = 'fpw';
export const PROFILE = 'pr';

// Roles
export const ROLES = {
  admin: 'admin',
  moderator: 'moderator',
  user: 'user',
};

// Recaptcha
export const RECAPTCHA_KEY_PROD = '6Lfml_UkAAAAAJ1gp8Xf6qJu8avMgT-7u_BZbd9A';
export const RECAPTCHA_KEY_DEV = '6LfpqOQjAAAAAGAfbLtqSNk6KTw3a2YjUVeFXxNq';
export const RECAPTCHA_KEY = IS_PRODUCTION ? RECAPTCHA_KEY_PROD : RECAPTCHA_KEY_DEV;
export const RECAPTCHA_ACTIONS = {
  login: 'login',
};
