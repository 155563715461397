import React from 'react';
import {
  List as ComponentList,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  Edit as ComponentEdit,
  SimpleForm,
  TextInput,
  TopToolbar,
  CreateButton,
  Create as ComponentCreate
} from 'react-admin';

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

export const List = () => {
  return (
    <ComponentList actions={<ListActions/>}>
      <Datagrid>
        <TextField source='id' sortable={true} />
        <TextField source='slug' sortable={true} />
        <TextField source='label' sortable={true} />
        <TextField source='type' sortable={true} />
        <TextField source='Region.label' sortable={true} />
        <DateField showTime={true} source='createdAt' />
        <DateField showTime={true} source='updatedAt' />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </ComponentList>
  );
};

export const Edit = (props) => {
  return (
    <ComponentEdit title={'Edit city'} {...props}>
      <SimpleForm>
        <TextInput disabled source='id' />
        <TextInput source='slug' />
        <TextInput source='label' />
        <TextInput source='type' />
      </SimpleForm>
    </ComponentEdit>
  );
};

export const Create = () => {
  return (
    <ComponentCreate>
      <SimpleForm>
        <TextInput source='slug' />
        <TextInput source='label' />
        <TextInput source='type' />
      </SimpleForm>
    </ComponentCreate>
  )
};

