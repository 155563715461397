import { RECAPTCHA_KEY, RECAPTCHA_ACTIONS, IS_LOCAL } from '../constants';

export class ReCaptcha {
  static load() {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha || IS_LOCAL) {
        return resolve();
      }

      try {
        const script = document.createElement('script');

        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
        script.addEventListener('load', resolve);
        document.body.appendChild(script);
      } catch (error) {
        reject(error);
      }
    });
  }

  static execute(name, callback) {
    if (IS_LOCAL) {
      return callback();
    }

    if (!Object.values(RECAPTCHA_ACTIONS).includes(name)) {
      console.error(new Error('Recaptcha actions are not registered!'));
    }

    const startExecute = () => {
      return new Promise((resolve) => {
        window.grecaptcha.execute(RECAPTCHA_KEY, { action: name }).then((token) => resolve(callback(token)));
      });
    };

    if (!window.grecaptcha) {
      this.load().then(() => {
        return startExecute();
      });
    }

    return startExecute();
  }
}
